<template>
  <CModal :show="showModal" :centered="true">
    <div slot="header" />
    <div slot="header-wrapper" />
    <div>
      <img src="../../../assets/img/invite-user.svg" class="modal-image" />
    </div>
    <div class="content">
      <h5>{{ $t("USER_MANAGEMENT.MODAL.USER_INVITED") }}</h5>
      <div class="text-muted">{{ $t("USER_MANAGEMENT.MODAL.MESSAGE") }}</div>
    </div>
    <div class="float-right button-div">
      <CButton
        color="durianprimary"
        class="button"
        @click="toggleModal"
      >{{ $t("USER_MANAGEMENT.MODAL.GOT_IT_BUTTON") }}</CButton>
    </div>
    <div slot="footer" />
  </CModal>
</template>

<script>
export default {
  name: "InviteUserModal",
  props: {
    showModal: Boolean,
    toggleModal: Function
  }
};
</script>

<style scoped>
.modal-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 7%;
}
.content {
  display: block;
  text-align: center;
  margin-top: 7%;
}
.button-div {
  margin-top: 4%;
  margin-right: 8%;
}
.button {
  width: 130%;
}
</style>
<template>
  <div v-page-access="permissions.userManagementCreate">
    <CCard>
      <CCardBody class="card-body">
        <CRow>
          <CCol md="3" sm="3">
            <div class="card-header-text">{{ $t("USER_MANAGEMENT.INVITE_USER.NEW_INVITE") }}</div>
          </CCol>
        </CRow>
        <hr />
        <CRow>
          <CCol col="12" class="permission-detail">
            <permission-details-view :isEditable="true" />
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
    <div class="float-right">
      <CButton class="cancel-button" @click="clearInvite">{{ $t("COMMON.CANCEL") }}</CButton>
      <CButton
        color="durianprimary"
        @click="inviteUser"
        :disabled="!isPayloadValid"
      >{{ $t("USER_MANAGEMENT.INVITE_USER.NEW_INVITE") }}</CButton>
    </div>
    <invite-user-modal :showModal="showModal" :toggleModal="toggleModal" />
  </div>
</template>

<script>
import * as types from "@/store/mutation-types";
import { inviteUser } from "@/api/user_management.api.js";
import PermissionDetailsView from "./components/PermissionDetailsView.vue";
import InviteUserModal from "./components/InviteUserModal.vue";

export default {
  name: "InviteUser",
  components: { PermissionDetailsView, InviteUserModal },
  data() {
    return {
      showModal: false
    };
  },
  methods: {
    toggleModal() {
      this.showModal = !this.showModal;
    },
    clearInvite() {
      this.$store.commit(types.resetUserManagementState);
      this.$router.go(-1);
    },
    async inviteUser() {
      let roleID;
      try {
        roleID = parseInt(this.getUserRoleID, 10);
      } catch (error) {
        this.showToaster(this.$t("COMMON.INTEGER_PARSING_ERROR"));
      }
      const payload = {
        merchant_id: this.getMerchantID,
        name: this.getUserName,
        email: this.getUserEmailID,
        role_id: roleID
      };
      try {
        await inviteUser(payload);
        this.$store.commit(types.resetUserManagementState);
        this.toggleModal();
      } catch (error) {
        this.showToaster(this.handleAPIError(error));
      }
    }
  },
  computed: {
    getMerchantID() {
      return this.$store.getters.getMerchantID;
    },
    getUserRoleID() {
      return this.$store.getters.getUserRoleID;
    },
    getUserEmailID() {
      return this.$store.getters.getUserEmail;
    },
    getUserName() {
      return this.$store.getters.getUserName;
    },
    isPayloadValid() {
      if (
        this.getUserEmailID.length === 0 ||
        this.getUserName.length === 0 ||
        !this.validateEmail(this.getUserEmailID)
      ) {
        return false;
      }
      return true;
    }
  }
};
</script>
<style scoped>
.card-header-text {
  font-size: 18px;
  font-weight: bold;
  padding-left: 10%;
}
.card-body {
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 3%;
}
.permission-detail {
  padding-left: 4%;
}
</style>

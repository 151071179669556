import { httpClient } from "./httpClient.js";

const CHANGE_PASSWORD = "/dashboard/user_profile/change_password";
const GET_PAGE_ACCESS_LIST = "/dashboard/user/permissions";
const GET_ROLE = '/dashboard/user/roles';
const INVITE_USER = '/dashboard/users/invite';
const GET_USER = '/dashboard/users';

const changePassword = async (payload) => {
  return await httpClient.dashboardService.post(CHANGE_PASSWORD, payload);
};

const getPageAccessList = async (params) => {
  return await httpClient.dashboardService.get(GET_PAGE_ACCESS_LIST, {
    params: params,
  });
};

const getRoles = async () => {
    return await httpClient.dashboardService.get(GET_ROLE);
}

const inviteUser = async (payload) => {
  return await httpClient.dashboardService.post(INVITE_USER, payload)
}

const getUserList = async(params) => httpClient.dashboardService.get(GET_USER, { "params": params });

export { 
  changePassword, 
  getPageAccessList, 
  getRoles, 
  inviteUser,
  getUserList
};

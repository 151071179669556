<template>
  <CRow>
    <CCol col="5" class="card-body-content">
      <label>{{ $t("USER_MANAGEMENT.INVITE_USER.USER_NAME") }}</label>
      <br />
      <input
        type="text"
        :disabled="!isEditable"
        @input="(event) => setUserName(event)"
        :value="getUserName"
        required
      />
      <br />

      <label>{{ $t("USER_MANAGEMENT.INVITE_USER.EMAIL_ADDRESS") }}</label>
      <br />
      <input
        :disabled="!isEditable"
        @input="(event) => setUserEmail(event)"
        :value="getUserEmailID"
        required
        :style="invalidEmailInputStyle"
      />
      <span
        class="error-feedback"
        v-if="getUserEmailID.length !== 0 && !validateEmail(getUserEmailID)"
      >
        <br />
        {{ $t("USER_MANAGEMENT.INVITE_USER.EMAIL_VALIDATION") }}
      </span>
      <br />

      <label>{{ $t("USER_MANAGEMENT.INVITE_USER.ROLE") }}</label>
      <br />
      <select @change="(event) => getPageAccessList(event)" required>
        <option
          v-for="(value, key) in roles"
          :selected="getUserSelectedRole === value.role"
          :key="key"
          :value="value.id + '###' + value.role"
        >{{ value.role }}</option>
      </select>
    </CCol>

    <CCol col="7" class="role-details-col">
      <div class="role-content">
        <br />
        <div class="normal-text">{{ getUserSelectedRole }}</div>
        <div class="sm-padding-top text-muted">{{ getRoleDescription }}</div>
        <div
          class="md-padding-top sm-padding-bottom normal-text"
        >{{ $t("USER_MANAGEMENT.INVITE_USER.PAGE_ACCESS") }}</div>
        <table>
          <tr v-for="(pages, key) in range" :key="key">
            <td class="text-muted pages-table-row">
              <img
                class="icon"
                :src="require('../../../assets/img/' + splitPageCode(page_list[pages].page_code))"
              />
              {{ page_list[pages].page_name }}
            </td>
            <td v-if="page_list[pages + 1]" class="text-muted pages-table-row">
              <img
                class="icon"
                :src="require('../../../assets/img/' + splitPageCode(page_list[pages+1].page_code))"
              />
              {{ page_list[pages + 1].page_name }}
            </td>
          </tr>
        </table>
      </div>
    </CCol>
  </CRow>
</template>

<script>
import * as types from "@/store/mutation-types";
import { getPageAccessList } from "@/api/user_management.api.js";
import { getRoles } from "@/api/user_management.api.js";

const SPLITTER = "###";
const IMG_EXTENSION = ".svg";

export default {
  name: "PermissionDetailsView",
  props: {
    isEditable: Boolean
  },
  data() {
    return {
      page_list: [],
      range: [],
      roles: []
    };
  },
  methods: {
    setUserName(event) {
      const value = event.target.value;
      this.$store.commit(types.setUserName, value);
    },
    setUserEmail(event) {
      const value = event.target.value;
      this.$store.commit(types.setUserEmail, value);
    },
    createRangeValues(totalPages) {
      this.range = [];
      for (let i = 0; i < totalPages; i = i + 1) {
        if (i % 2 == 0) {
          this.range.push(i);
        }
      }
    },
    async getPageAccessList(event) {
      const roleID = event.target.value.split(SPLITTER)[0];
      const roleValue = event.target.value.split(SPLITTER)[1];
      const params = { role: roleID };
      this.$store.commit(types.setRoleID, roleID);
      this.$store.commit(types.setRole, roleValue);
      try {
        const response = await getPageAccessList(params);
        this.page_list = response.data.data;
        this.createRangeValues(this.page_list.length);
      } catch (error) {
        this.showToaster(this.handleAPIError(error));
      }
    },
    async getInitialAccessList() {
      const roleID = this.getRole;
      const params = { role: roleID };
      try {
        const response = await getPageAccessList(params);
        this.page_list = response.data.data;
        this.createRangeValues(this.page_list.length);
      } catch (error) {
        this.showToaster(this.handleAPIError(error));
      }
    },
    async getRoles() {
      try {
        const response = await getRoles();
        this.roles = response.data.data;
      } catch (error) {
        this.showToaster(this.handleAPIError(error));
      }
    },
    splitPageCode(code) {
      return code.split(".")[0] + IMG_EXTENSION;
    }
  },
  computed: {
    getRole() {
      return this.$store.getters.getUserRoleID;
    },
    getUserName() {
      return this.$store.getters.getUserName;
    },
    getUserEmailID() {
      return this.$store.getters.getUserEmail;
    },
    getUserSelectedRole() {
      return this.$store.getters.getUserSelectedRole;
    },
    getRoleDescription() {
      const selectedRole = this.getRole;
      const roleLength = this.roles.length;
      let description = "";
      for (let key = 0; key < roleLength; key++) {
        if (this.roles[key].id.toString() === selectedRole) {
          description = this.roles[key].description;
          break;
        }
      }
      return description;
    },
    invalidEmailInputStyle() {
      if (
        this.getUserEmailID.length !== 0 &&
        !this.validateEmail(this.getUserEmailID)
      ) {
        return {
          "border-color": "#e55353"
        };
      } else {
        return {
          "border-color": "#d8dbe0"
        };
      }
    }
  },
  mounted() {
    this.getRoles();
    this.getInitialAccessList();
  }
};
</script>

<style scoped>
input {
  background: #ffffff;
  border: 1px solid #d8dbe0;
  border-radius: 4px;
  height: 36px;
  padding-left: 2%;
  font-size: 14px;
  width: 90%;
}
input:focus {
  outline-width: 0;
}
input:focus:invalid {
  border-color: #e55353;
}
select {
  background: #ffffff;
  border: 1px solid #d8dbe0;
  border-radius: 4px;
  height: 36px;
  padding-left: 2%;
  font-size: 14px;
  width: 90%;
}
select:focus {
  outline-width: 0;
}
label {
  margin-top: 20px;
}
.normal-text {
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #4e4f52;
}
.sm-padding-top {
  padding-top: 10px;
}
.md-padding-top {
  padding-top: 20px;
}
.pages-table-row {
  width: 30%;
  padding-bottom: 7px;
}
.sm-padding-bottom {
  margin-bottom: 15px;
}
.role-details-col {
  border-left: 1px solid #d9dbdf;
}
.role-content {
  margin-left: 8%;
}
.card-body-content {
  margin-bottom: 10%;
}
.icon {
  width: 18px;
  margin-right: 4%;
}
.error-feedback {
  font-size: 11px;
  color: #e55353;
}
</style>
